<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <encuesta></encuesta>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <estadisticas ref="estadisticas"></estadisticas>
    </v-col>

    <v-col
      cols="12"
      md="12"
    >
      <grafico-covid ref="dataChart"></grafico-covid>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import Estadisticas from './Estadisticas'
import GraficoCovid from './GraficoCovid'
import Encuesta from './Encuesta'
import salud from '@/services/salud'

export default {
  name: 'Dashboard',
  components: {
    GraficoCovid,
    Estadisticas,
    Encuesta,
  },
  data: () => ({
    params: {
      cve: '000',
      nom: 'Nacional',
    },
    loading: true,
  }),
  mounted() {
    this.getDataGraf()
  },
  methods: {
    getDataGraf() {
      salud
        .getDataSalud(this.params)
        .then(resp => {
          if (resp.data.error) {
            this.$refs.estadisticas.setError(resp.data.error)
            this.$refs.dataChart.setError(resp.data.error)
            this.loading = false
          } else {
            const statisticsData = [
              {
                title: 'Confirmados',
                total: resp.data.Confirmados,
              },
              {
                title: 'Negativos',
                total: resp.data.Negativos,
              },
              {
                title: 'Sospechosos',
                total: resp.data.Sospechosos,
              },
              {
                title: 'Defunciones',
                total: resp.data.Defunciones,
              },
              {
                title: 'Hospitalizados',
                total: resp.data.Hospitalizados,
              },
              {
                title: 'Ambulatorios',
                total: resp.data.Ambulatorios,
              },
              {
                title: 'Hombres',
                total: resp.data.PoblacionMasculina,
              },
              {
                title: 'Mujeres',
                total: resp.data.PoblacionFemenina,
              },
              {
                title: 'Recuperados',
                total: resp.data.Recuperados,
              },
              {
                title: 'Activos',
                total: resp.data.Activos,
              },
            ]
            this.$refs.estadisticas.getDataCon(statisticsData, this.params.nom)
            const data = JSON.parse(resp.data.DayChartMain)
            this.$refs.dataChart.setData(data)
            this.loading = false
          }
        })
    },
  },
  setup() { },
}
</script>
